"use strict";

// jQuery free

onDOMReady(function () {
  var userOrders = document.querySelectorAll('.userOrder');
  userOrders.forEach(function (userOrder) {
    userOrder.addEventListener('click', function () {
      var userOrderOpenIcon = userOrder.querySelector('.userOrderOpenIcon');
      var userOrderDetail = userOrder.nextElementSibling;
      if (userOrderDetail.hidden) {
        userOrderOpenIcon.classList.add('userOrderOpenIcon--opened');
        userOrderDetail.hidden = false;
      } else {
        userOrderOpenIcon.classList.remove('userOrderOpenIcon--opened');
        userOrderDetail.hidden = true;
      }
      userOrders.forEach(function (u) {
        if (u !== userOrder) {
          u.querySelector('.userOrderOpenIcon').classList.remove('userOrderOpenIcon--opened');
          u.nextElementSibling.hidden = true;
        }
      });
    });
  });
});